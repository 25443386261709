<template>
  <div>
    <FullWidthImageHero
      :image="require('@/assets/images/employee-benefits/employer_hero.jpg')"
      :mobileImage="
        require('@/assets/images/employee-benefits/employer_hero_mobile.jpg')
      "
      height="600px"
      mobileHeight="300px"
      class="sm-mb-140px"
    >
      <b-container class="h-100">
        <b-row align-h="start" align-v="center" class="h-100">
          <b-col cols="12" md="7" lg="6">
            <b-row>
              <b-col class="hero-text p-4 p-lg-5 bg-white box-shadow">
                <h1 class="h1 font-weight-bolder mb-4">
                  A Solution Your Employees Will Actually Use
                </h1>
                <p class="h4">
                  It’s a win-win. Financial wellness for all.
                </p>
                <div class="mt-4 d-flex align-items-center">
                  <SignupLinkButton
                    :to="{ name: 'EmployerSignup' }"
                    pill
                    variant="secondary"
                    class="employee-benefits-btn mr-4"
                    >Contact Us</SignupLinkButton
                  >
                  <a @click="openVideo" href="#" class="open-video">
                    See how it works
                    <PlayButton class="ml-1" />
                  </a>
                </div>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </b-container>
    </FullWidthImageHero>
    <b-container fluid class="bg-gray-200 pt-lg-5 pt-4">
      <b-row>
        <b-col cols="12" class="text-center mt-4">
          <h2 class="h2 font-weight-bolder mb-4">
            The Impact of Financially Stressed Employees
          </h2>
        </b-col>
      </b-row>
      <b-row>
        <b-col col="12" class="mt-5 mb-4">
          <div class="sm-container">
            <b-row>
              <b-col cols="12" md="4">
                <div
                  class="d-flex justify-content-center align-items-center flex-column text-center mb-5"
                >
                  <IconClock class="mb-5" />
                  <h3 class="h4 font-weight-bold mb-4">
                    “Employees are distracted”
                  </h3>
                  <p>10x more likely to not finish tasks at work</p>
                </div>
              </b-col>
              <b-col cols="12" md="4">
                <div
                  class="d-flex justify-content-center align-items-center flex-column text-center mb-5"
                >
                  <IconUsersSlash class="mt-4 mb-4" />
                  <h3 class="h4 font-weight-bold mb-4">
                    “We have low attendance”
                  </h3>
                  <p>7x more likely to miss a day at work</p>
                </div>
              </b-col>
              <b-col cols="12" md="4">
                <div
                  class="d-flex justify-content-center align-items-center flex-column text-center mb-5"
                >
                  <IconRunning class="mb-5" />
                  <h3 class="h4 font-weight-bold mb-4">
                    “We have high turnover”
                  </h3>
                  <p>2x more likely to be looking for a new job</p>
                </div>
              </b-col>
            </b-row>
          </div>
        </b-col>
      </b-row>
    </b-container>
    <b-container class="pt-3 features">
      <b-row>
        <b-col cols="12" class="text-center mt-sm-5 pt-5">
          <h2 class="h2 font-weight-bolder mb-4">
            How Our Tools Help
          </h2>
          <p>
            Reap the benefits of higher employee engagement, fewer absences, and
            greater levels of productivity.
          </p>
        </b-col>
      </b-row>
      <b-row align-h="center" class="mt-lg-5 pt-3">
        <b-col cols="12" xl="8">
          <div class="alternating-blocks">
            <div class="block">
              <div class="block-image mr-md-4 employee-benefits">
                <b-img
                  src="@/assets/images/features_scoremaster_phone.jpg"
                  alt="ScoreMaster® credit score monitoring tool"
                  fluid
                  class="d-none d-md-block"
                />
                <b-img
                  src="@/assets/images/employee-benefits/features_scoreboost_mobile.png"
                  alt="ScoreMaster® credit score monitoring tool"
                  fluid
                  class="d-md-none"
                />
              </div>
              <div class="block-content align-self-center mb-5">
                <h3 class="h3 font-weight-bold mb-4">
                  A reimagined credit score experience for your employees
                </h3>

                <ul>
                  <li>
                    Demystifies credit score points by showing exactly how much
                    to pay and when to reach their target score faster
                  </li>
                  <li>
                    Plan includes ‘Post Payment By’ dates targeted around
                    creditor reporting schedules
                  </li>
                </ul>
              </div>
            </div>
            <div class="block image-right">
              <div class="block-image employee-benefits d-flex ml-md-4">
                <b-img
                  src="@/assets/images/employee-benefits/features_moneymanager_phone.jpg"
                  alt="money manager tool"
                  fluid
                  class="d-none d-md-block"
                />
                <b-img
                  src="@/assets/images/employee-benefits/features_moneymanager_mobile.png"
                  alt="money manager tool"
                  fluid
                  class="d-md-none"
                />
              </div>
              <div class="block-content align-self-center mb-5">
                <h3 class="h3 font-weight-bold mb-4">
                  One-stop-shop for credit cards, bank statements, and accounts
                </h3>

                <ul>
                  <li>
                    See transactions, payments due, spending trends, and credit
                    utilization
                  </li>
                  <li>
                    Reduce distractions and stress, increase engagement and
                    productivity
                  </li>
                </ul>
              </div>
            </div>
            <div class="block">
              <div class="block-image mr-md-4 employee-benefits">
                <b-img
                  src="@/assets/images/features_privacymaster_phone.jpg"
                  alt="credit score tracker"
                  fluid
                  class="d-none d-md-block"
                />
                <b-img
                  src="@/assets/images/employee-benefits/features_privacymaster_mobile.png"
                  alt="credit score tracker"
                  fluid
                  class="d-md-none"
                />
              </div>
              <div class="block-content align-self-center mb-5">
                <h3 class="h3 font-weight-bold mb-4">
                  The best way to protect your team from identity fraud and
                  scams
                </h3>

                <ul>
                  <li>
                    Stop scammers from stealing personal data with a single
                    swipe
                  </li>
                  <li>
                    Manage employee privacy risk at a glance
                  </li>
                </ul>
              </div>
            </div>
            <div class="block image-right">
              <div class="block-image d-flex ml-md-4 employee-benefits">
                <b-img
                  src="@/assets/images/employee-benefits/features_fraud_insurance_phone.jpg"
                  alt="$1 Million Identity Fraud Insurance"
                  fluid
                  class="d-none d-md-block"
                />
                <b-img
                  src="@/assets/images/employee-benefits/features_fraud_insurance_mobile.png"
                  alt="$1 Million Identity Fraud Insurance"
                  fluid
                  class="d-md-none"
                />
              </div>
              <div class="block-content align-self-center mb-5">
                <p class="h3 font-weight-bold mb-4">
                  Give your employees peace of mind in the face of fraud
                </p>

                <ul>
                  <li>
                    Zero-deductible insurance for employees’ whole household
                  </li>
                  <li>
                    Covers out-of-pocket expenses for ID recovery and
                    pre-existing fraud they were unaware of
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </b-col>
      </b-row>
    </b-container>
    <b-container fluid class="bg-gray-100 mt-5 pt-4">
      <div class="sm-container">
        <h2 class="h1 mb-5 pt-5 font-weight-bolder text-center">
          Additional Features
        </h2>
        <b-row align-h="center" class="feature-grid pt-5 pb-4">
          <div
            class="mb-5 col-lg-3 col-md-6 col-12 d-flex align-items-center flex-column text-center mw-300px"
          >
            <IconSCR style="height:60px;" class="icon icon-primary mb-4" />
            <p class="font-weight-semibold">
              Smart Credit Report<sup>&reg;</sup>
            </p>
            <p class="mw-320px">
              View your credit report in a simple and innovative way.
            </p>
          </div>
          <div
            class="mb-5 col-lg-3 col-md-6 col-12 d-flex align-items-center flex-column text-center"
          >
            <IconAlerts style="height:60px;" class="icon icon-primary mb-4" />
            <p class="font-weight-semibold">Alerts</p>
            <p class="mw-320px">
              Receive alerts for suspicious activity and credit monitoring
              events.
            </p>
          </div>
          <div
            class="mb-5 col-lg-3 col-md-6 col-12 d-flex align-items-center flex-column text-center"
          >
            <IconAB style="height:60px;" class="icon icon-primary mb-4" />
            <p class="font-weight-semibold">Action Buttons</p>
            <p class="mw-320px">
              Report inaccuracies and ask questions directly with your
              creditors.
            </p>
          </div>
          <div
            class="mb-5 col-lg-3 col-md-6 col-12 d-flex align-items-center flex-column text-center"
          >
            <Icon3BR style="height:60px;" class="icon icon-primary mb-4" />
            <p class="font-weight-semibold">3B Report &amp; Scores</p>
            <p class="mw-320px">
              Track and compare your credit activity across all 3 credit
              bureaus.
            </p>
          </div>
        </b-row>
      </div>
    </b-container>
    <b-container fluid class="pt-5 mt-5 mb-5">
      <div class="sm-container text-center">
        <b-row>
          <b-col>
            <h2 class="h2 font-weight-bolder mb-4">
              Happy Employees For Life!
            </h2>
            <p class="mb-5">
              Contact us today to learn more about ScoreMaster<sup>&reg;</sup>
              Employee Benefits.
            </p>
            <EmployeeBenefitsHubSpotForm />
          </b-col>
        </b-row>
      </div>
    </b-container>
    <b-container>
      <b-row>
        <b-col cols="12">
          <p class="text-center">
            <small
              >Sources:
              <u
                ><a
                  target="_blank"
                  href="https://www.wtwco.com/en-US/Insights/2022/07/2-in-5-us-employees-struggle-to-meet-basic-needs-as-financial-wellbeing-deteriorates"
                  >2022 Global Benefits Attitudes Survey</a
                ></u
              >,
              <u
                ><a
                  target="_blank"
                  href="https://www.apa.org/news/podcasts/speaking-of-psychology/financial-stress"
                  >American Psychological Association</a
                ></u
              >
              and
              <u
                ><a
                  target="_blank"
                  href="https://www.hrmorning.com/articles/financial-wellness-program-benefits/"
                  >HR Morning</a
                ></u
              ></small
            >
          </p>
        </b-col>
      </b-row>
    </b-container>
    <ModalVideo
      channel="vimeo"
      videoId="693718297?h=7fe1e10a4f"
      :isOpen.sync="videoIsOpen"
    />
  </div>
</template>

<script>
import ModalVideo from '@/components/common/ModalVideo'
import IconRunning from '@/assets/svg/icon_running.svg'
import IconUsersSlash from '@/assets/svg/icon_users_slash.svg'
import IconClock from '@/assets/svg/icon_clock.svg'
import PlayButton from '@/assets/svg/icon_play_button.svg'
import IconSCR from '@/assets/svg/icon_SCR.svg'
import Icon3BR from '@/assets/svg/icon_3BR.svg'
import IconAB from '@/assets/svg/icon_AB.svg'
import IconAlerts from '@/assets/svg/icon_alerts.svg'
import EmployeeBenefitsHubSpotForm from '@/components/common/EmployeeBenefitsHubSpotForm'

export default {
  name: 'EmployeeBenefitsEmployers',
  metaInfo() {
    return {
      link: [
        {
          rel: 'canonical',
          href: `https://www.scoremaster.com${this.$route.path}`,
        },
      ],
    }
  },
  components: {
    EmployeeBenefitsHubSpotForm,
    ModalVideo,
    IconRunning,
    IconUsersSlash,
    IconClock,
    PlayButton,
    IconSCR,
    Icon3BR,
    IconAB,
    IconAlerts,
  },
  data() {
    return {
      videoIsOpen: false,
      schemaData: [
        {
          '@context': 'http://schema.org',
          '@type': 'LocalBusiness',
          name: 'ScoreMaster Employee Benefits',
          address: {
            '@type': 'PostalAddress',
            streetAddress: '2983 Michelson Dr.',
            addressLocality: 'Irvine',
            addressRegion: 'California',
            postalCode: '92612',
            addressCountry: 'United States',
          },
          telephone: '+18772100180',
          openingHours:
            'Mo 0800-1700 Tu 0800-1700 We 0800-1700 Th 0800-1700 Fr 0800-1700',
          geo: {
            '@type': 'GeoCoordinates',
            name: 'ScoreMaster',
            postalCode: '92612',
            latitude: '33.6707',
            longitude: '-117.8537',
            description: 'You can find our office in this location.',
          },
          url: 'https://www.scoremaster.com/',
          logo:
            'https://www.find-us-here.com/images/business_images/amp/scoremaster_main_photo.png',
          image:
            'https://www.scoremaster.com/img/employer_benefit_scorecentric.c6940dcf.jpg',
          priceRange: '$$',
          aggregateRating: {
            ratingValue: '5',
            ratingCount: '65',
          },
        },
        {
          '@context': 'https://schema.org',
          '@type': 'Service',
          serviceType: 'Employee Benefits Services',
          '@id': 'https://www.scoremaster.com/employee-benefits/#service',
          name: 'Employee Benefits',
          additionalType: 'https://en.wikipedia.org/wiki/Employee_Benefits',
          sameAs: 'http:productontology.org/id//Employee_Benefits',
          brand: 'ScoreMaster Employee Benefits',
          description:
            'Give ScoreMaster® to your employees as a benefit so they can get better deals and save money.',
          image: {
            '@type': 'ImageObject',
            '@id': 'https://www.scoremaster.com/employee-benefits/#image',
            url:
              'https://www.scoremaster.com/img/employer_benefit_scorecentric.c6940dcf.jpg',
            caption: 'Employee Benefits Services',
          },
          potentialAction: 'Get ScoreMaster',
          provider: {
            '@id': 'https://www.google.com/maps?cid=11670868934081200217',
          },
          serviceOutput:
            'ScoreMaster for Companies: Reducing Employee Financial Stress',
          url: 'https://www.scoremaster.com/employee-benefits/',
        },
        {
          '@context': 'http://schema.org',
          '@type': 'FAQPage',
          isPartOf: 'https://www.scoremaster.com/#website',
          '@id': 'https://www.scoremaster.com/employee-benefits/#faq',
          mainEntity: [
            {
              '@type': 'Question',
              name: 'Will a poor credit score affect employment?',
              acceptedAnswer: {
                '@type': 'Answer',
                text:
                  "When you hear things like “a bad credit score can prevent you from getting a job,” it's actually not true. That's because employers don't pull your actual credit scores like a lender might. An employer must inform the applicant that the credit check might be used as part of the employment decision.",
              },
            },
            {
              '@type': 'Question',
              name: 'What are the benefits of having a good credit score?',
              acceptedAnswer: {
                '@type': 'Answer',
                text:
                  'Some benefits of having a good credit score includes significant savings on interest rates, better terms and availability on loan products, access to best credit cards, insurance discounts, more housing options and security deposit waivers on utilities.',
              },
            },
            {
              '@type': 'Question',
              name: 'What are the benefits of having a credit score over 800?',
              acceptedAnswer: {
                '@type': 'Answer',
                text:
                  "You'll qualify for lower interest rates and higher credit limits. With an 800-plus credit score, you are considered very likely to repay your debts, so lenders can offer you better deals.",
              },
            },
          ],
        },
      ],
    }
  },
  methods: {
    openVideo: function(e) {
      e.preventDefault()
      this.videoIsOpen = !this.videoIsOpen
    },
  },
  mounted() {
    document.dispatchEvent(new Event('x-app-rendered'))
    this.$store.commit('metaData/updatePageSchema', this.schemaData)
  },
  beforeRouteLeave(to, from, next) {
    this.$store.commit('metaData/resetDefaultSchema')
    next()
  },
}
</script>
