<template>
  <div>
    <FullWidthImageHero
      :image="
        require('@/assets/images/employee-benefits/employee_benefits_hero.jpg')
      "
      :mobileImage="
        require('@/assets/images/employee-benefits/employee_benefits_hero_mobile.jpg')
      "
      height="600px"
      mobileHeight="300px"
      class="sm-mb-140px"
    >
      <b-container class="h-100">
        <b-row align-h="start" align-v="center" class="h-100">
          <b-col cols="12" md="7" lg="6">
            <b-row>
              <b-col class="hero-text p-4 p-lg-5 bg-white box-shadow">
                <h1 class="h1 font-weight-bolder mb-4">
                  The Financial Stress Reliever
                </h1>
                <p class="h4">
                  ScoreMaster<sup>&reg;</sup> helps people live more financially
                  secure lives. We make understanding credit easier and identity
                  fraud prevention painless.
                </p>
                <div class="mt-4 d-flex align-items-center">
                  <SignupLinkButton
                    :to="{ name: 'EmployerSignup' }"
                    pill
                    variant="secondary"
                    class="employee-benefits-btn mr-4"
                    >Contact Us</SignupLinkButton
                  >
                  <a @click="openVideo" href="#" class="open-video">
                    See how it works
                    <PlayButton class="ml-1" />
                  </a>
                </div>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </b-container>
    </FullWidthImageHero>
    <b-container class="mt-5 pt-5">
      <b-row class="gutters-on-small">
        <b-col cols="12" md="6" xl="4">
          <div class="p-4 p-lg-5 bg-white box-shadow mb-4">
            <h2 class="h2 font-weight-bolder mb-4">
              For Employers
            </h2>
            <p>
              Increase retention of top talent by providing employees with
              financial wellness as part of their benefits package.
            </p>
            <router-link
              :to="{ name: 'EmployeeBenefitsEmployers' }"
              class="w-100 h-7/2rem btn mt-4 btn-secondary btn-large rounded-pill d-flex justify-content-center align-items-center"
              >ScoreMaster<sup>&reg;</sup> for Employers</router-link
            >
          </div>
        </b-col>
        <b-col cols="12" md="6" xl="4">
          <div class="p-4 p-lg-5 bg-white box-shadow mb-4">
            <h2 class="h2 font-weight-bolder mb-4">
              For Brokers
            </h2>
            <p>
              Add value for your clients by providing a holistic, interactive
              financial wellness solution containing actionable advice.
            </p>
            <router-link
              :to="{ name: 'EmployeeBenefitsBrokers' }"
              class="w-100 h-7/2rem btn mt-4 btn-secondary btn-large rounded-pill d-flex justify-content-center align-items-center"
              >ScoreMaster<sup>&reg;</sup> for Brokers</router-link
            >
          </div>
        </b-col>
        <b-col cols="12" md="6" xl="4">
          <div class="p-4 p-lg-5 bg-white box-shadow mb-4">
            <h2 class="h2 font-weight-bolder mb-4">
              For Providers
            </h2>
            <p>
              Enhance your offering with widgets that help users manage their
              credit, money, and privacy to achieve their financial goals.
            </p>
            <router-link
              :to="{ name: 'EmployeeBenefitsProviders' }"
              class="w-100 h-7/2rem btn mt-4 btn-secondary btn-large rounded-pill d-flex justify-content-center align-items-center"
              >ScoreMaster<sup>&reg;</sup> for Providers</router-link
            >
          </div>
        </b-col>
      </b-row>
    </b-container>
    <b-container fluid class="bg-gray-200 mt-5 pt-5">
      <b-row>
        <b-col cols="12" class="text-center mt-4">
          <h2 class="h2 font-weight-bolder mb-4">
            Total Health Includes Financial Health
          </h2>
          <p>
            Personal finance is ranked as a leading cause of stress in the
            country.
          </p>
        </b-col>
      </b-row>
      <b-row>
        <b-col col="12" class="mt-4 mb-4">
          <div class="sm-container">
            <b-row>
              <b-col cols="12" md="4">
                <div class="financial-health-multiplier">
                  <span>9X</span>
                  <p class="px-4">
                    9x more likely to have troubled work relationships
                  </p>
                </div>
              </b-col>
              <b-col cols="12" md="4">
                <div class="financial-health-multiplier">
                  <span>9X</span>
                  <p class="px-4">90% of Americans feel anxious about money</p>
                </div>
              </b-col>
              <b-col cols="12" md="4">
                <div class="financial-health-multiplier">
                  <span>2X</span>
                  <p class="px-4">
                    2x more likely to report poor health overall
                  </p>
                </div>
              </b-col>
            </b-row>
          </div>
        </b-col>
      </b-row>
    </b-container>
    <b-container class="mt-5 pt-5 no-gutters">
      <h2 class="h2 font-weight-bolder mb-4 text-center">
        Why ScoreMaster<sup>&reg;</sup>?
      </h2>
      <p class="text-center mb-5">
        Since 2003, our platform has empowered people to take action on their
        credit and to feel in control of their finances.
      </p>
      <div class="alternating-blocks fullwidth pt-4">
        <div class="block image-left">
          <div class="block-image">
            <b-img
              :src="
                require('@/assets/images/employee-benefits/control_your_credit.jpg')
              "
              alt="Control Your Credit"
              fluid
            />
          </div>
          <div class="block-content">
            <h3 class="h3 font-weight-bold mb-4 text-md-left text-center">
              Control Your Credit
            </h3>
            <p class="text-md-left text-center">
              Strategic payment plans based on data-driven insights to pay off
              debt while earning more credit score points.
            </p>
          </div>
        </div>
        <div class="block image-right">
          <div class="block-image">
            <b-img
              :src="
                require('@/assets/images/employee-benefits/manage_your_money.jpg')
              "
              alt="employee benefit identity fraud insurance"
              fluid
            />
          </div>
          <div class="block-content">
            <h3 class="h3 font-weight-bold mb-4 text-md-left text-center">
              Manage Your Money
            </h3>
            <p class="text-md-left text-center">
              Full-service resource for financial literacy education on credit
              score management, balancing budgets, savings, etc.
            </p>
          </div>
        </div>
        <div class="block image-left">
          <div class="block-image">
            <b-img
              :src="
                require('@/assets/images/employee-benefits/protect_your_privacy.jpg')
              "
              alt="Protect your privacy"
              fluid
            />
          </div>
          <div class="block-content">
            <h3 class="h3 font-weight-bold mb-4 text-md-left text-center">
              Protect Your Privacy
            </h3>
            <p class="text-md-left text-center">
              Directly request the removal of your private information from data
              broker websites with a single swipe.
            </p>
          </div>
        </div>
      </div>
    </b-container>
    <b-container fluid class="bg-primary mt-5 pt-5 px-0 text-white">
      <b-row>
        <b-col cols="12" class="text-center mt-4">
          <h2 class="h2 font-weight-bolder mb-4">
            Data Privacy is Precarious
          </h2>
        </b-col>
      </b-row>
      <b-row>
        <b-col col="12" class="mt-4 mb-4">
          <div class="sm-container">
            <b-row>
              <b-col cols="12" md="4">
                <div class="financial-health-multiplier">
                  <span class="text-white">79%</span>
                  <p>
                    79% of internet users feel they have lost control of their
                    personal data
                  </p>
                </div>
              </b-col>
              <b-col cols="12" md="4">
                <div class="financial-health-multiplier">
                  <span class="text-white">81%</span>
                  <p>
                    81% of internet users believe that their information is
                    vulnerable to hackers
                  </p>
                </div>
              </b-col>
              <b-col cols="12" md="4">
                <div class="financial-health-multiplier">
                  <span class="text-white">33%</span>
                  <p>
                    33% of email and social media users were victims of at least
                    one cyberattack
                  </p>
                </div>
              </b-col>
            </b-row>
          </div>
        </b-col>
      </b-row>
    </b-container>
    <b-container fluid class="bg-gray-200 pt-5 px-3">
      <b-row>
        <b-col cols="12" class="pt-3">
          <div class="p-4 p-lg-5 bg-white mb-5 want-to-learn-more-box">
            <h2 class="h2 font-weight-bolder mb-4 pt-3">
              Want to Learn More?
            </h2>
            <div class="d-flex justif-center align-items-center pb-3">
              <SignupLinkButton
                :to="{ name: 'EmployerSignup' }"
                pill
                variant="secondary"
                class="employee-benefits-btn mr-4"
                >Contact Us</SignupLinkButton
              >
              <a @click="openVideo" href="#" class="open-video">
                See how it works
                <PlayButton class="ml-1" />
              </a>
            </div>
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12">
          <p class="text-center">
            <small
              >Sources:
              <u
                ><a
                  target="_blank"
                  href="https://www.hrmorning.com/articles/financial-wellness-program-benefits/"
                  >HR Morning</a
                ></u
              >,
              <u
                ><a target="_blank" href="https://identitytheft.org/"
                  >Identitytheft.org</a
                ></u
              >
              and
              <u
                ><a target="_blank" href="https://legaljobs.io/"
                  >leagaljobs.io</a
                ></u
              ></small
            >
          </p>
        </b-col>
      </b-row>
    </b-container>
    <ModalVideo
      channel="vimeo"
      videoId="693718297?h=7fe1e10a4f"
      :isOpen.sync="videoIsOpen"
    />
  </div>
</template>

<script>
import ModalVideo from '@/components/common/ModalVideo'
import PlayButton from '@/assets/svg/icon_play_button.svg'

export default {
  name: 'EmployeeBenefits',
  metaInfo() {
    return {
      link: [
        {
          rel: 'canonical',
          href: `https://www.scoremaster.com${this.$route.path}`,
        },
      ],
    }
  },
  components: {
    ModalVideo,
    PlayButton,
  },
  data() {
    return {
      videoIsOpen: false,
      schemaData: [
        {
          '@context': 'http://schema.org',
          '@type': 'LocalBusiness',
          name: 'ScoreMaster Employee Benefits',
          address: {
            '@type': 'PostalAddress',
            streetAddress: '2983 Michelson Dr.',
            addressLocality: 'Irvine',
            addressRegion: 'California',
            postalCode: '92612',
            addressCountry: 'United States',
          },
          telephone: '+18772100180',
          openingHours:
            'Mo 0800-1700 Tu 0800-1700 We 0800-1700 Th 0800-1700 Fr 0800-1700',
          geo: {
            '@type': 'GeoCoordinates',
            name: 'ScoreMaster',
            postalCode: '92612',
            latitude: '33.6707',
            longitude: '-117.8537',
            description: 'You can find our office in this location.',
          },
          url: 'https://www.scoremaster.com/',
          logo:
            'https://www.find-us-here.com/images/business_images/amp/scoremaster_main_photo.png',
          image:
            'https://www.scoremaster.com/img/employer_benefit_scorecentric.c6940dcf.jpg',
          priceRange: '$$',
          aggregateRating: {
            ratingValue: '5',
            ratingCount: '65',
          },
        },
        {
          '@context': 'https://schema.org',
          '@type': 'Service',
          serviceType: 'Employee Benefits Services',
          '@id': 'https://www.scoremaster.com/employee-benefits/#service',
          name: 'Employee Benefits',
          additionalType: 'https://en.wikipedia.org/wiki/Employee_Benefits',
          sameAs: 'http:productontology.org/id//Employee_Benefits',
          brand: 'ScoreMaster Employee Benefits',
          description:
            'Give ScoreMaster® to your employees as a benefit so they can get better deals and save money.',
          image: {
            '@type': 'ImageObject',
            '@id': 'https://www.scoremaster.com/employee-benefits/#image',
            url:
              'https://www.scoremaster.com/img/employer_benefit_scorecentric.c6940dcf.jpg',
            caption: 'Employee Benefits Services',
          },
          potentialAction: 'Get ScoreMaster',
          provider: {
            '@id': 'https://www.google.com/maps?cid=11670868934081200217',
          },
          serviceOutput:
            'ScoreMaster for Companies: Reducing Employee Financial Stress',
          url: 'https://www.scoremaster.com/employee-benefits/',
        },
        {
          '@context': 'http://schema.org',
          '@type': 'FAQPage',
          isPartOf: 'https://www.scoremaster.com/#website',
          '@id': 'https://www.scoremaster.com/employee-benefits/#faq',
          mainEntity: [
            {
              '@type': 'Question',
              name: 'Will a poor credit score affect employment?',
              acceptedAnswer: {
                '@type': 'Answer',
                text:
                  "When you hear things like “a bad credit score can prevent you from getting a job,” it's actually not true. That's because employers don't pull your actual credit scores like a lender might. An employer must inform the applicant that the credit check might be used as part of the employment decision.",
              },
            },
            {
              '@type': 'Question',
              name: 'What are the benefits of having a good credit score?',
              acceptedAnswer: {
                '@type': 'Answer',
                text:
                  'Some benefits of having a good credit score includes significant savings on interest rates, better terms and availability on loan products, access to best credit cards, insurance discounts, more housing options and security deposit waivers on utilities.',
              },
            },
            {
              '@type': 'Question',
              name: 'What are the benefits of having a credit score over 800?',
              acceptedAnswer: {
                '@type': 'Answer',
                text:
                  "You'll qualify for lower interest rates and higher credit limits. With an 800-plus credit score, you are considered very likely to repay your debts, so lenders can offer you better deals.",
              },
            },
          ],
        },
      ],
    }
  },
  methods: {
    openVideo: function(e) {
      e.preventDefault()
      this.videoIsOpen = !this.videoIsOpen
    },
  },
  mounted() {
    document.dispatchEvent(new Event('x-app-rendered'))
    this.$store.commit('metaData/updatePageSchema', this.schemaData)
  },
  beforeRouteLeave(to, from, next) {
    this.$store.commit('metaData/resetDefaultSchema')
    next()
  },
}
</script>
