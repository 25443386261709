<template>
  <div>
    <FullWidthImageHero
      :image="require('@/assets/images/employee-benefits/providers_hero.jpg')"
      :mobileImage="
        require('@/assets/images/employee-benefits/providers_hero_mobile.jpg')
      "
      height="600px"
      mobileHeight="300px"
      class="sm-mb-140px"
    >
      <b-container class="h-100">
        <b-row align-h="start" align-v="center" class="h-100">
          <b-col cols="12" md="7" lg="6">
            <b-row>
              <b-col class="hero-text p-4 p-lg-5 bg-white box-shadow">
                <h1 class="h1 font-weight-bolder mb-4">
                  Sweeten the Deal
                </h1>
                <p class="h4">
                  Use ScoreMaster<sup>&reg;</sup> widgets to create a holistic
                  financial wellness solution for your customers.
                </p>
                <div class="mt-4 d-flex align-items-center">
                  <SignupLinkButton
                    :to="{ name: 'EmployerSignup' }"
                    pill
                    variant="secondary"
                    class="employee-benefits-btn mr-4"
                    >Contact Us</SignupLinkButton
                  >
                  <a @click="openVideo" href="#" class="open-video">
                    See how it works
                    <PlayButton class="ml-1" />
                  </a>
                </div>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </b-container>
    </FullWidthImageHero>
    <b-container fluid class="bg-gray-100 pt-4">
      <div class="sm-container">
        <b-row>
          <b-col cols="12" lg="8" class="m-auto">
            <h2 class="h2 mb-4 pt-5 font-weight-bolder text-center">
              Your À La Carte Toolkit
            </h2>
            <p class="mb-5 text-center">
              You have something good, but you want to be great. It’s as easy as
              “Add to Cart” to accelerate your development, supplement your
              solution, and stand out among the crowd.
            </p>
          </b-col>
        </b-row>
        <b-row align-h="center" class="feature-grid pt-5 pb-4">
          <div
            class="mb-5 col-lg-3 col-md-6 col-12 d-flex align-items-center flex-column text-center mw-300px"
          >
            <IconST style="height:60px;" class="icon icon-primary mb-4" />
            <p class="font-weight-semibold">ScoreTracker</p>
            <p class="mw-320px px-3">
              Evaluate creditworthiness with four scores that lenders,
              employers, and creditors use.
            </p>
          </div>
          <div
            class="mb-5 col-lg-3 col-md-6 col-12 d-flex align-items-center flex-column text-center"
          >
            <Icon3BR style="height:60px;" class="icon icon-primary mb-4" />
            <p class="font-weight-semibold">
              Smart Credit Report<sup>&reg;</sup>
            </p>
            <p class="mw-320px px-3">
              Understand your credit report with a simplified, horizontal view.
            </p>
          </div>
          <div
            class="mb-5 col-lg-3 col-md-6 col-12 d-flex align-items-center flex-column text-center"
          >
            <IconSCR style="height:60px;" class="icon icon-primary mb-4" />
            <p class="font-weight-semibold">ScoreBoost<sup>&trade;</sup></p>
            <p class="mw-320px px-3">
              Know how much to pay and when with strategic payment plans.
            </p>
          </div>
          <div
            class="mb-5 col-lg-3 col-md-6 col-12 d-flex align-items-center flex-column text-center mw-300px"
          >
            <IconPM style="height:60px;" class="icon icon-primary mb-4" />
            <p class="font-weight-semibold">PrivacyMaster<sup>&reg;</sup></p>
            <p class="mw-320px px-3">
              Stop data traffickers from sharing or selling your information.
            </p>
          </div>
        </b-row>
      </div>
    </b-container>
    <b-container fluid class="my-5 py-4">
      <b-row>
        <b-col>
          <h2 class="h2 mb-5 font-weight-bolder text-center">
            How We Can Partner
          </h2>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12" md="6" xl="5" offset-xl="2">
          <img
            class="mw-100 mb-5"
            :src="
              require('@/assets/images/employee-benefits/how_we_can_partner.jpg')
            "
            alt="how we can partner"
          />
        </b-col>
        <b-col
          class="d-flex flex-column justify-content-center text-center text-md-left"
          cols="12"
          md="6"
          xl="3"
          offset-xl="1"
        >
          <h2 class="h3 font-weight-bold mb-4">
            Co-Brand and White Label Opportunities
          </h2>
          <p>
            Get your fully-branded personalized website that is scaled and
            responsive to your unique needs.
          </p>
        </b-col>
      </b-row>
    </b-container>
    <b-container fluid class="bg-gray-200 py-5 mt-5">
      <div class="sm-container text-center">
        <b-row>
          <b-col>
            <h2 class="h2 font-weight-bolder mb-4">
              Enhance Your Current Offering!
            </h2>
            <p class="mb-5">
              Contact us today to learn more about ScoreMaster<sup>&reg;</sup>
              Employee Benefits.
            </p>
            <EmployeeBenefitsHubSpotForm />
          </b-col>
        </b-row>
      </div>
    </b-container>
    <ModalVideo
      channel="vimeo"
      videoId="693718297?h=7fe1e10a4f"
      :isOpen.sync="videoIsOpen"
    />
  </div>
</template>

<script>
import ModalVideo from '@/components/common/ModalVideo'
import PlayButton from '@/assets/svg/icon_play_button.svg'
import IconSCR from '@/assets/svg/icon_SCR.svg'
import Icon3BR from '@/assets/svg/icon_3BR.svg'
import IconPM from '@/assets/svg/icon_PM.svg'
import IconST from '@/assets/svg/icon_ST.svg'
import EmployeeBenefitsHubSpotForm from '@/components/common/EmployeeBenefitsHubSpotForm'

export default {
  name: 'EmployeeBenefitsProviders',
  metaInfo() {
    return {
      link: [
        {
          rel: 'canonical',
          href: `https://www.scoremaster.com${this.$route.path}`,
        },
      ],
    }
  },
  components: {
    ModalVideo,
    PlayButton,
    IconSCR,
    Icon3BR,
    IconPM,
    IconST,
    EmployeeBenefitsHubSpotForm,
  },
  data() {
    return {
      videoIsOpen: false,
      schemaData: [
        {
          '@context': 'http://schema.org',
          '@type': 'LocalBusiness',
          name: 'ScoreMaster Employee Benefits',
          address: {
            '@type': 'PostalAddress',
            streetAddress: '2983 Michelson Dr.',
            addressLocality: 'Irvine',
            addressRegion: 'California',
            postalCode: '92612',
            addressCountry: 'United States',
          },
          telephone: '+18772100180',
          openingHours:
            'Mo 0800-1700 Tu 0800-1700 We 0800-1700 Th 0800-1700 Fr 0800-1700',
          geo: {
            '@type': 'GeoCoordinates',
            name: 'ScoreMaster',
            postalCode: '92612',
            latitude: '33.6707',
            longitude: '-117.8537',
            description: 'You can find our office in this location.',
          },
          url: 'https://www.scoremaster.com/',
          logo:
            'https://www.find-us-here.com/images/business_images/amp/scoremaster_main_photo.png',
          image:
            'https://www.scoremaster.com/img/employer_benefit_scorecentric.c6940dcf.jpg',
          priceRange: '$$',
          aggregateRating: {
            ratingValue: '5',
            ratingCount: '65',
          },
        },
        {
          '@context': 'https://schema.org',
          '@type': 'Service',
          serviceType: 'Employee Benefits Services',
          '@id': 'https://www.scoremaster.com/employee-benefits/#service',
          name: 'Employee Benefits',
          additionalType: 'https://en.wikipedia.org/wiki/Employee_Benefits',
          sameAs: 'http:productontology.org/id//Employee_Benefits',
          brand: 'ScoreMaster Employee Benefits',
          description:
            'Give ScoreMaster® to your employees as a benefit so they can get better deals and save money.',
          image: {
            '@type': 'ImageObject',
            '@id': 'https://www.scoremaster.com/employee-benefits/#image',
            url:
              'https://www.scoremaster.com/img/employer_benefit_scorecentric.c6940dcf.jpg',
            caption: 'Employee Benefits Services',
          },
          potentialAction: 'Get ScoreMaster',
          provider: {
            '@id': 'https://www.google.com/maps?cid=11670868934081200217',
          },
          serviceOutput:
            'ScoreMaster for Companies: Reducing Employee Financial Stress',
          url: 'https://www.scoremaster.com/employee-benefits/',
        },
        {
          '@context': 'http://schema.org',
          '@type': 'FAQPage',
          isPartOf: 'https://www.scoremaster.com/#website',
          '@id': 'https://www.scoremaster.com/employee-benefits/#faq',
          mainEntity: [
            {
              '@type': 'Question',
              name: 'Will a poor credit score affect employment?',
              acceptedAnswer: {
                '@type': 'Answer',
                text:
                  "When you hear things like “a bad credit score can prevent you from getting a job,” it's actually not true. That's because employers don't pull your actual credit scores like a lender might. An employer must inform the applicant that the credit check might be used as part of the employment decision.",
              },
            },
            {
              '@type': 'Question',
              name: 'What are the benefits of having a good credit score?',
              acceptedAnswer: {
                '@type': 'Answer',
                text:
                  'Some benefits of having a good credit score includes significant savings on interest rates, better terms and availability on loan products, access to best credit cards, insurance discounts, more housing options and security deposit waivers on utilities.',
              },
            },
            {
              '@type': 'Question',
              name: 'What are the benefits of having a credit score over 800?',
              acceptedAnswer: {
                '@type': 'Answer',
                text:
                  "You'll qualify for lower interest rates and higher credit limits. With an 800-plus credit score, you are considered very likely to repay your debts, so lenders can offer you better deals.",
              },
            },
          ],
        },
      ],
    }
  },
  methods: {
    openVideo: function(e) {
      e.preventDefault()
      this.videoIsOpen = !this.videoIsOpen
    },
  },
  mounted() {
    document.dispatchEvent(new Event('x-app-rendered'))
    this.$store.commit('metaData/updatePageSchema', this.schemaData)
  },
  beforeRouteLeave(to, from, next) {
    this.$store.commit('metaData/resetDefaultSchema')
    next()
  },
}
</script>
